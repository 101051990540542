<template>
  <div class="bim">
    <iframe
      :src="link"
      v-if="link"
      sandbox="allow-scripts allow-same-origin"
    ></iframe>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  name: "cache-bim",
  data() {
    return {
      link: null,
      path: "/hero/websocket/environmentalData",
      // pubPath: "https://gdjcywebdata001.oss-cn-shenzhen.aliyuncs.com/bim/localmodel/BIMModelManage",
      pubPath: "https://gdjcywebdata001.oss-cn-shenzhen.aliyuncs.com/bim/localmodel/BIM协同管理",
    }
  },
  created() {
    const url = `${this.pubPath}/${this.projectId}`
    const link = `${process.env.VUE_APP_BASE}/qmodel?url=${decodeURIComponent(url)}&unit=栋`
    this.link = link
  },
  computed: {
    ...mapGetters(["projectId"]),
  },
}
</script>
<style lang="less" scoped>
.bim {
  width: 100%;
  height: calc(100% - 30px);
  position: relative;
  & > iframe {
    width: 100%;
    height: 100%;
  }
}
</style>
